import React from 'react';
import FacebookLogin from 'react-facebook-login';
import Cookies from 'js-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';


 

 
function FaceBookAuthButton() {

  const [searchparams, setsearchparams] = useSearchParams();
  const { code, state } = useSearchParams()
  alert(searchparams.get("code")); 
  alert(state);
  console.log('this is code:' + code);
  console.log('this is state' + state);


  const responseFacebook = (response) => {

    if (response?.status === "unknown") {
      console.log(response);
      console.error('Sorry!', 'Something went wrong with facebook Login.');
      return;
  }
  console.log(response);

    Cookies.set('CCCL', response.accessToken, { expires: 7, secure: true, sameSite: 'strict' });

      postFacebookAccount(response);
       // If login successful, set cookie
  
     //  Cookies.set('CCCL:', username, { expires: 7 }); // Cookie expires in 7 days
  }
  
  const componentClicked = (response) => {
      //console.log('Clicked');
    }
  
    const postFacebookAccount = async (response) => {
      var customer = 0;
      postFacebookAccountasync(response).then(cust => {
  
        handleClick();
         // customer = cust;
         // redirectToCheckout();
      //console.log(cust.customerIDdata); // fetched movies
    })
    };
  
    async function postFacebookAccountasync(response) {
     // console.log(response.profileObj);
       const response2 = await  fetch('https://prod.carrollcountychristmaslights.com/api/Account/Facebook',{
      // const response2 = await  fetch('https://localhost:7080/api/Account/Facebook',{
           method: 'POST',
           headers:{'Content-Type':'application/json'},
             body: JSON.stringify({  "accessToken": response.accessToken,
             "email": response.email,
             "name": response.name,
             })
         });
         
       const customerIDdata = await response2.json();
       return customerIDdata;
  
     };

     function timeout(number) {
      return new Promise( res => setTimeout(res, number) );
    }
  
     const navigate = useNavigate();
  
     function handleClick() {
      timeout(1000);
       navigate('/Account');
     }

    
    return(
        <FacebookLogin
        appId="8146990888763560"
        autoLoad={false}
        fields="name,email"
        onClick={componentClicked}
        callback={responseFacebook} 
        redirectUri='https://www.carrollcountychristmaslights.com/Facebook'
        />
    
    );
};


export default FaceBookAuthButton;
