import Header from "../headers/light.js";
import { useParams } from 'react-router-dom';
//import { useNavigate } from 'react-router-dom';




function Success()  {
  //const navigate = useNavigate();

  const { sid, hid, cid, eid, paid } = useParams()
  console.log(paid);
  //console.log('This is the session checkout id' + sid);
  //console.log('this is the houseid' + hid)
  //console.log('this is the customer id' + cid)
  

  const postScheduledJob = async () => {
    var customer = 0;
    postScheduledJobasync().then(cust => {
        customer = cust;
       // redirectToCheckout();
    //console.log(cust.customerIDdata); // fetched movies
  }).then(async function() {
    console.log(customer);
    //alert("All Done!")
    //navigate(`/home`);
    console.log('success');
    //    successUrl: `${window.location.origin}/success/{CHECKOUT_SESSION_ID}/${data.houseID}/${customer}/${email}`,
     })
  };

  async function postScheduledJobasync() {
     const response = await  fetch('https://prod.carrollcountychristmaslights.com/api/ScheduleJob',{
    // const response = await  fetch('https://localhost:7080/api/ScheduleJob',{
         method: 'POST',
         headers:{'Content-Type':'application/json'},
           body: JSON.stringify({  "customerID": cid,
           "houseID": hid,
           "paymentID": sid,
           "paid": paid === "1" ? true : false })
       });
     const customerIDdata = await response.json();
     return customerIDdata;
   };

   postScheduledJob();


    return (
      <>
      <Header/>
      <br/>
      <br/>
      <div>
        <h1>Success</h1>
        <h2>Thank you for your purchase! We will send an email out to: {eid} to confirm the order in 24 hours</h2>
      </div>
      </>
    );
  };
  
  export default Success;
  