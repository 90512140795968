import Cookies from 'js-cookie';
import Header from "components/headers/light.js";
//import { useNavigate } from 'react-router-dom';

function LogOut()  {
    //const navigate = useNavigate();
  
    //console.log('This is the session checkout id' + sid);
    //console.log('this is the houseid' + hid)
    //console.log('this is the customer id' + cid)
    Cookies.remove('CCCL');
   // const navigate = useNavigate();

  
   //   navigate('/Home');
    
  
    
  
      return (
        <>
        <Header/>
        <br/>
        <br/>
        <div>
          <h1>Successfully Logged out</h1>
        </div>
        </>
      );
    };
    
    export default LogOut;
    