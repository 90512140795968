import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';







export default function HorizontalLinearAlternativeLabelStepper({ payment, orderdate, installdate, removaldate }) {

 {/* const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
  ];*/}

  let active = (removaldate != null) ? 2 : (installdate != null) ? 1 : 0;
  const steps = [
    'purchased on ' + orderdate + ' using ' + payment,
 installdate == null ? 'Install date TBD' : installdate,
    removaldate == null ? 'Removal date TBD' : removaldate,
  ];

  return (
      <Stepper activeStep={active} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
  );
}